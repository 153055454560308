<template>
  <div>
    <div class="text-accent">
      <slot />
    </div>
  </div>
  <Toaster />
</template>

<script type="ts" setup>
import { Toaster } from '~/components/toast'

useLoggedInChecker()
</script>
